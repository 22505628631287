<template>
    <div id="app">
        <!-- <van-popup
            v-model="Loadshow"
            style="border-radius: 4px"
            overlay-class="overOpa"
            @click="closeScreen()"
        >
            <ListScreen @closeScreen="closeScreen" />
        </van-popup> -->
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <!-- <transition name="component-fade" mode="out-in">
      <router-view v-if="$route.meta.animate"></router-view>
    </transition> -->
        <router-view
            v-if="!$route.meta.keepAlive && !$route.meta.animate"
        ></router-view>
        <transition v-else name="component-fade">
            <router-view v-if="$route.meta.animate"></router-view>
        </transition>
    </div>
</template>
<script>
import ListScreen from "@/components/ListScreen.vue";
// import { Swipe, SwipeItem } from "vant";
import { screen,getList,getCategoryChild, shoppCarList, getDetail,getDownList } from "./api/http.js";
import {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
} from "@/utils/storage.js";
import Vue from "vue";
// Vue.use(Swipe).use(SwipeItem);
import 'swiper/css/swiper.min.css'

// import { activeHome } from "../api/http.js";


export default {
    components: {
        ListScreen,
    },
    data() {
        return {
            list: [],
            t: null,
            timer: null,
            Loadshow: false,
            categorysArr:[]
        };
    },
    created() {
        this.hasOperate(this.showScreen, 300000);
        let shop_id = this.$route.query.shop_id || 0;
        let wxapp_id = this.$route.query.wxapp_id
        if (shop_id) {
            if (
                getLocalStorage("shop_id") &&
                getLocalStorage("shop_id") !== shop_id
            ) {
                removeLocalStorage("shop_id");
                setLocalStorage("shop_id", shop_id);
            } else if (!getLocalStorage("shop_id")) {
                setLocalStorage("shop_id", shop_id);
            }
        }
        if (wxapp_id) {
            if (
                getLocalStorage("wxapp_id") &&
                getLocalStorage("wxapp_id") != wxapp_id
            ) {
                removeLocalStorage("wxapp_id");
                setLocalStorage("wxapp_id", wxapp_id);
            } else if (!getLocalStorage("wxapp_id")) {
                setLocalStorage("wxapp_id", wxapp_id);
            }
        }
        
     
        let threeSwitch = this.$route.query.threeSwitch 

        if(process.env.VUE_APP_ISAPP=='true'){
            threeSwitch = 'on'
            // //打包东莞北京山河集
            //threeSwitch = 'off'
            setLocalStorage("shop_id", '10020');//打包北京山河集
            //setLocalStorage("shop_id", '10023');//打包深圳山河集
            //setLocalStorage("shop_id", '10021');//打包宫廷缘
        }
        if (threeSwitch) {
            if (
                getLocalStorage("threeSwitch") &&
                getLocalStorage("threeSwitch") !== threeSwitch
            ) {
                removeLocalStorage("threeSwitch");
                setLocalStorage("threeSwitch", threeSwitch);
            } else if (!getLocalStorage("threeSwitch")) {
                setLocalStorage("threeSwitch", threeSwitch);
            }
        }
     

        if (getLocalStorage("shop_id")) {
            getDetail(getLocalStorage("shop_id")).then((res) => {
                if(!res){
                    return
                }

                setLocalStorage("is_gold_price_more", res.data.detail.is_gold_price_more);
                
                this.$store.commit("setLogo", res.data.detail.logo.file_path);
                this.$store.commit("setVlogo", res.data.detail.v_logo.file_path);
                this.$store.commit("setCode", res.data.detail.is_code_off);
                this.$store.commit("setVideo", res.data.detail.is_video_off);
                const is_scan_code_order = res.data.detail.is_scan_code_order
                if (is_scan_code_order || is_scan_code_order == 0) {
                    if (
                        getLocalStorage("is_scan_code_order") &&
                        getLocalStorage("is_scan_code_order") != is_scan_code_order
                    ) {
                        removeLocalStorage("is_scan_code_order");
                        setLocalStorage("is_scan_code_order", is_scan_code_order);
                    } else if (!getLocalStorage("is_scan_code_order")) {
                        setLocalStorage("is_scan_code_order", is_scan_code_order);
                    }
                }
            });
        }else {
            this.$store.commit("setCode", 0);
            this.$store.commit("setVideo", 0);
        }

        if(process.env.VUE_APP_ISAPP=='true'){
            //localStorage.setItem("login_token",'1a32a08eb543749bf3bf28d18a72813a');

            window.electronAPI.on('weixin-login-token',(weixinToken,userType)=>{
                localStorage.setItem("login_token",weixinToken);
                localStorage.setItem("user_type",userType);
                
            })
            // const token = localStorage.getItem("login_token");

            // if(!token){
            //     Promise.all([this.getCategorys()]).then(()=>{
            //         this.getRouceList()
            //     }) 
                
            // }else{
            //     this.getRouceList()
            // }
			
		}

        

        

        
       
    },
    methods: {
        getCategorys(){
            return getCategoryChild().then(res =>{
                
                if(res&&res.data&&res.data.list){
                    let list = res.data.list
                    list.forEach(item =>{
                        if(item.category_id!='0'){

                            item.child.forEach(childItem=>{
                                this.categorysArr.push(childItem.category_id)
                            })

                        }
                        
                    })
                }
            })
        },
        
        getModelName(modelId,modelURL){
			const tempArr = modelURL.split('.')
			let suffix = tempArr[tempArr.length-1]
			let fileName = modelId +'.' + suffix
			return fileName
		},
        getRouceList(){

			window.electronAPI.startUpdateModels()
			window.electronAPI.on('updateCurrentPageEvent',(currentPage)=>{

				const modelsList  = []
	
                const token = localStorage.getItem("login_token");

                let type = '0'
                let page = currentPage

                // if(!token){
                //     type = this.categorysArr[page]
                //     page = 1

                //     if(type==undefined){
                //        window.electronAPI.updateModels(JSON.stringify([]),currentPage)
                //        return  
                //     }
                // }

                
                getDownList(
                page,
                'all',
                true,
                type,
                '',
                token,
                '',
                5,
                -1,
                true,
                4,
                true,
   
            ).then((res) => {
   
                if(res&&res.data&&res.data.list&&res.data.list.data){
                    let len = res.data.list.data.length

                    if(len > 0){
                        res.data.list.data.forEach((item,index)=>{

                            if(item.model_file){
                                item.model_file['fileName'] = this.getModelName(item.goods_id,item.model_file.file_name)
                            }

                            if(item.goods_screen){
                                item.goods_screen['videoName'] = this.getModelName(item.goods_id,item.goods_screen.video_front_url)
                            }
                            
								
                            if(item.image){
                              window.electronAPI.updateImgs(JSON.stringify(item.image))  
                            }
                            let object = {
                                id:item.goods_id,
                                // fileName:fileName,
                                // imgUrl:item.thumbnail,
                                // modelUrl:item.resourceUrl,
                                // updateTime:item.updateTime
                                video:item.goods_screen,
                                //imgs:item.image,
                                model:item.model_file
                            }
                            modelsList.push(object)
                    
                            if(index==len-1){
                                window.electronAPI.updateModels(JSON.stringify(modelsList),currentPage)
                            }
                        })

                    }else{//没有数据
                        window.electronAPI.updateModels(JSON.stringify([]),currentPage)
                    }
                }else{//没有数据
                    window.electronAPI.updateModels(JSON.stringify([]),currentPage)
                }
               
            });

			})

            window.electronAPI.on('updateCategoryCurrentPageEvent',(currentPage)=>{

				const modelsList  = []
	
                const token = localStorage.getItem("login_token");

                let type = '0'
                let page = currentPage

                if(!token){
                    type = this.categorysArr[page-1]
                    page = 1

                    if(type==undefined){
                       window.electronAPI.updateCategoryModels(JSON.stringify([]),currentPage)
                       return  
                    }
                }

                getDownList(
                page,
                'all',
                true,
                type,
                '',
                token,
                '',
                5,
   
            ).then((res) => {
   
                if(res&&res.data&&res.data.list&&res.data.list.data){
                    let len = res.data.list.data.length

                    if(len > 0){
                        res.data.list.data.forEach((item,index)=>{

                            if(item.model_file){
                                item.model_file['fileName'] = this.getModelName(item.goods_id,item.model_file.file_name)
                            }

                            if(item.goods_screen){
                                item.goods_screen['videoName'] = this.getModelName(item.goods_id,item.goods_screen.video_front_url)
                            }

                            if(item.image){
                              window.electronAPI.updateImgs(JSON.stringify(item.image))  
                            }
                            
									
                            let object = {
                                id:item.goods_id,
                                // fileName:fileName,
                                // imgUrl:item.thumbnail,
                                // modelUrl:item.resourceUrl,
                                // updateTime:item.updateTime
                                video:item.goods_screen,
                                //imgs:item.image,
                                model:item.model_file
                            }
                            modelsList.push(object)
                    
                            if(index==len-1){
                                window.electronAPI.updateCategoryModels(JSON.stringify(modelsList),currentPage)
                            }
                        })

                    }else{//没有数据
                        window.electronAPI.updateCategoryModels(JSON.stringify([]),currentPage)
                    }
                }else{//没有数据
                    window.electronAPI.updateCategoryModels(JSON.stringify([]),currentPage)
                }
               
            });

			})
	
		},
        cart() {
            const token = localStorage.getItem("login_token");
            shoppCarList(token).then((res) => {
                console.log(res);
            });
        },
        hasOperate(callback, second) {
            //second是检测未操作的时间，秒为单位，callback是该时间段未点击需要执行的函数
            var status = true;
            var timer = null;
            document.body.addEventListener("touchstart", function () {
                sessionStorage.removeItem("screen");
                status = true;
            });
            document.body.addEventListener("touchend", function () {
                countTime();
            });
            setInterval(function () {
                if (!status && sessionStorage.getItem("screen") == null) {
                    callback();
                    sessionStorage.setItem("screen", "1");
                    status = true;
                }
            }, 1);
            function countTime() {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    status = false;
                }, second);
            }
            countTime();
        },
        showScreen() {
            this.Loadshow = true;
        },
        closeScreen() {
            sessionStorage.removeItem("screen");
            this.Loadshow = false;
        },
        getQueryString() {
            var url = document.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                strs.forEach((ele) => {
                    if (ele.indexOf("shop_id") > -1) {
                        theRequest[ele.split("=")[0]] = decodeURIComponent(
                            ele.split("=")[1]
                        );
                    }
                });
            }
            return theRequest;
        },
    },
};
</script>

<style lang="scss">
body::-webkit-scrollbar {
    display: none;
}
@import url("https://at.alicdn.com/t/c/font_1161655_v2axc1n0rqb.css"); //iconfont

@import url("https://at.alicdn.com/t/font_1161655_umjlyudqmo.css"); //iconfont

@import url("https://at.alicdn.com/t/font_1161655_3q22e2igpmg.css"); //iconfont
@import url('https://at.alicdn.com/t/c/font_1161655_608x8a92j7p.css');
@import url('https://at.alicdn.com/t/c/font_1161655_b8q33kq671v.css');
@import url('//at.alicdn.com/t/c/font_1161655_2nffuj4m3lr.css');

@import url('//at.alicdn.com/t/c/font_1161655_cmzmrqeyp1c.css');
input[type="button"],
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: none;
}
textarea {
    -webkit-appearance: none;
}
</style>
<style>
.component-fade-enter,
.component-fade-leave-to {
    opacity: 0;
    /* transform: translateY(100%); */
}
.component-fade-enter-active,
.component-fade-leave-active {
    transition: 1s;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
}
#app {
    /* width:1000px; */
}
html,
body {
    min-height: 100%;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
}
body {
    background: rgba(250, 250, 250, 1);
}
.overOpa {
    background-color: rgba(0, 0, 0, 1) !important;
}

</style>
