import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filters';
import 'amfe-flexible'
import { Col, Row,DropdownMenu, DropdownItem,Icon,Button, Field, Tabbar, TabbarItem, Search, NavBar, Popup, Pagination, Dialog, Toast, Cell, CellGroup, PullRefresh, List, Loading, NumberKeyboard, Stepper, Checkbox, CheckboxGroup ,Grid, GridItem} from 'vant'

import VueAwesomeSwiper from 'vue-awesome-swiper';

Vue.use(VueAwesomeSwiper /* { default options with global component } */);
import VueSignaturePad from 'vue-signature-pad';
// import vconsole from 'vconsole'
// let Vconsole =  new vconsole
// import animated from 'animate.css'
Vue.use(VueSignaturePad);
// Vue.use(animated)
Vue.use(VueAwesomeSwiper)
// Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.use(Button)
.use(Row)
.use(Col)
.use(Grid)
.use(GridItem)
.use(Icon).use(Field).use(Tabbar).use(TabbarItem).use(Search).use(NavBar).use(Popup).use(Pagination).use(Dialog).use(Toast).use(PullRefresh).use(List).use(Loading).use(NumberKeyboard).use(Stepper).use(Checkbox).use(CheckboxGroup).use(Cell).use(CellGroup)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, {
  passive: false
});

var lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
  var now = Date.now();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);

let homeScrollTop = 0;
Vue.prototype.$homeScroll = homeScrollTop;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const cache = await caches.open('shanheji');
Vue.directive('intercept-img', {
 async bind (el, binding) {
    const url = binding.value || el.getAttribute('src');
    let newSrc = url; // 或者根据你的逻辑来决定新的URL

    let response = await cache.match(url);
    if (response) {

       // 如果缓存中存在图片，返回图片的 blob URL
      const blob = await response.blob();
      newSrc =  URL.createObjectURL(blob);

   } else {
       // 如果缓存中不存在图片，从网络获取并缓存，然后返回图片的原始 URL
       const fetchResponse = await fetch(url);
       const cloneResponse = fetchResponse.clone();
       cache.put(url, cloneResponse);
   }
    el.setAttribute('src', newSrc);
  }
});

// let timer
// const handleUserActivity = () =>  {  
//   clearTimeout(timer); // 清除上一次设置的定时器  
//   timer = setTimeout(() => {
//     const {path} = router.app._route
//     if(path != '/cash'){
//       router.push('/cash')
//     }
//   }, 60000); // 设置60秒的无操作时间间隔  
// }  
// handleUserActivity()
// // 监听用户的操作  
// document.addEventListener('touchstart', handleUserActivity);